.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-popper {
  margin-left: -4px;
}

.react-datepicker {
  background-color: #212337;
  border-color: #6a6a9f;
  color: white;
  width: 100%;
}

.react-datepicker__month-container {
  width: auto;
}

.react-datepicker-time__header{
  color: white;
}

.react-datepicker__time-list-item:hover {
  width: 70px;
  cursor: pointer;
  background-color: #6a6a9f !important;
}

.react-datepicker__time-list {
  width: 75px !important;
}
.react-datepicker__time-container {
  width: 75px !important;
}

.react-datepicker__time-box {
  width: 74px !important;
  background-color: #212337;
  border-color: #6a6a9f;
  color: white;
}

.react-datepicker__header {
  background-color: #212337;
  border-color: #6a6a9f;
  width: 100%;
}

.react-datepicker__navigation--previous {
  top: -2px;
  border-right-color: #6a6a9f;
}

.react-datepicker__navigation--next {
  top: -2px;
  border-left-color: #6a6a9f;
}
.react-datepicker__day-names {
  width: 100%;
}

.react-datepicker__current-month {
  color: white;
  font-size: 1rem;
}
.react-datepicker__day-name {
  color: white;
  font-size: 1rem;
}
.react-datepicker__day {
  color: white;
  font-size: 1rem;
}

.react-datepicker__day--selected {
  background-color: #023aff;
}
.react-datepicker__day--keyboard-selected {
  background-color: #023aff;
}

.react-datepicker__day:hover {
  background-color: #6a6a9f;
}
