.rc-time-picker {
  border-bottom: 2px solid #fff !important;
}

.rc-time-picker-input {
  background: transparent !important;
  border: none !important;
  color: #fff !important;
  font-size: 1.4rem;
  width: 80%;
}

.rc-time-picker-input::placeholder {
  font-size: 1.4rem !important;
  color: #6a6a9f;
}

.rc-time-picker-panel-input-wrap {
  border-bottom: 1px solid #6a6a9f;
  width: 100% !important;
}

.rc-time-picker-panel-inner {
  background-color: #212337 !important;
  border: 1px solid #6a6a9f;
  box-shadow: none;
  width: calc(100% + 30px);
}

.rc-time-picker-panel-combobox {
  background-color: #212337 !important;
}

.rc-time-picker-panel-input {
  background-color: #212337 !important;
  color: #fff !important;
}

.rc-time-picker-panel-select {
  background-color: #212337 !important;
  border: 1px solid #6a6a9f;
  border-bottom: none;
  border-top: none;
}
.rc-time-picker-panel-select-option-selected {
  background-color: #023aff !important;
}

.rc-time-picker-panel-select li:hover {
  background-color: #6a6a9f !important;
}
